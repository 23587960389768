import axios from "axios";

export async function axiosGet(
  givenURL,
  givenAction,
  givenDB,
  givenSelection,
  givenInsertion,
  givenID
) {
  try {
    let res = await axios({
      method: "post",
      url: givenURL,
      headers: {
        "content-type": "application/json",
      },
      data: {
        action: givenAction,
        database: givenDB,
        selection: givenSelection,
        insertion: givenInsertion,
        id: givenID,
      },
      responseType: "json",
    });
    if (res.status === 200) {
      //Return the status of the request
      //console.log("axios status : " + res.status);

      //Return the response Data
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
}

import React, { useEffect, useState } from "react";
import { axiosGet } from "../axios/axiosGet";

const IndexPage = () => {
  const [myURL, setMyURL] = useState("...");
  const [myText, setMyText] = useState("...");

  //Handle the Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    sendForm();
  };

  //send the form
  async function sendForm() {
    console.log("URL changed to : " + myURL);
    console.log("text changed to : " + myText);

    //Set the changing statements
    let change = "audiourl='" + myURL + "'";
    let changetext = "audiotext='" + myText + "'";
    let y = await axiosGet(
      "https://enmimaleta.com/php/displayNewsletter.php",
      "update",
      "audio",
      "",
      change + ", " + changetext,
      "id=1"
    );
    console.log("Form response : " + JSON.stringify(y));
  }

  //Get and display the url
  async function GetURL() {
    let x = await axiosGet(
      "https://cuisineau.com/php/displayNewsletter.php",
      "get",
      "audio",
      ["audiourl, ", "audiotext, ", "id"],
      ""
    );
    console.log(x[0][0]);
    console.log(x[0][1]);
    setMyURL(x[0][0]);
    setMyText(x[0][1]);
  }

  //Runs when the frontend is loaded
  useEffect(() => {
    GetURL();
  }, []);

  return (
    <>
      <form
        onSubmit={(event) => {
          handleSubmit(event);
        }}
        method="post"
      >
        <input
          type="text"
          value={myURL}
          onChange={(e) => setMyURL(e.target.value)}
          required
        />

        <textarea
          value={myText}
          onChange={(e) => setMyText(e.target.value)}
          cols="30"
          rows="10"
          required
        ></textarea>

        <button type="submit">Cambiar</button>
      </form>
    </>
  );
};

export default IndexPage;
